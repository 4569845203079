import React from "react"
import { Link } from "gatsby"

import { rhythm, scale } from "../utils/typography"
import ScrollTop from "react-scrolltop-button"

class Layout extends React.Component {
  render() {
    const { location, title, children, toc } = this.props
    const rootPath = `${__PATH_PREFIX__}/`
    let tableOfContents
    let header
    let centeredStyle = {
      marginLeft: `auto`,
      marginRight: `auto`,
      maxWidth: rhythm(24),
      padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
    }
    if (location.pathname === rootPath) {
      header = (
        <h1
          style={{
            ...scale(1.5),
            marginBottom: rhythm(1.5),
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h1>
      )
      tableOfContents = ""
    } else {
      header = (
        <h3
          style={{
            fontFamily: `Montserrat, sans-serif`,
            marginTop: 0,
          }}
        >
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </h3>
      )
      tableOfContents = (
        <section className="tableOfContents">
          <h2>Table Of Contents</h2>
          <div dangerouslySetInnerHTML={{ __html: toc }} />
        </section>
      )
    }
    return (
      <div className="blogWrapper">
        {tableOfContents}
        <div
          className="blogContent"
          style={{
            marginLeft: `auto`,
            marginRight: `auto`,
            maxWidth: rhythm(24),
            padding: `${rhythm(1.5)} ${rhythm(3 / 4)}`,
          }}
        >
          <header>{header}</header>

          <main>
            {children}
            <ScrollTop />
          </main>
          <footer>
            © {new Date().getFullYear()}, Built with
            {` `}
            <a href="https://www.gatsbyjs.org">Gatsby</a>
          </footer>
        </div>
      </div>
    )
  }
}

export default Layout
